<template>
    <div class="alert-report__panel-left">
        <router-link to="/" class="alert-report__nav-link">
            <img src="@/assets/icons/vuesax/linear/arrow-left-white.svg" width="20" height="20" alt="">
            Alert Report
        </router-link>

        <!-- Associate Info -->
        <AssociateInfo :associate-info="alertReport.associateInfo" :evidences="alertReport.evidences" />

        <!-- Events Time Line -->
        <TimeLine :locations="alertReport.locations" @location="(value) => setMarkerCords = value" />
        
        <!-- Buttons Options -->
        <AlertButtonsOptions :status-id="alertReport.associateInfo.alertStatusId" :alert-id="alertId" />
    </div>

    <!-- Google Maps  -->
    <Maps :location="setMarkerCords" />

    <!-- Content Loader -->
    <ContentLoader :hidden-loader="hiddenLoader" />
</template>

<script setup>
import { alertReportServices } from '../Services/AlertReportServices.js'
import { alertReportModel } from '../Model/AlertReportModel.js'
import { onBeforeMount, ref } from 'vue'
import TimeLine from './TimeLine.vue'
import AssociateInfo from './AssociateInfo.vue'
import ContentLoader from '@/common/ContentLoader.vue'
import Maps from '../Components/Maps.vue'
import { RouterLink } from 'vue-router'
import AlertButtonsOptions from './AlertButtonsOptions.vue'

const props = defineProps(['alertId'])
const { getAlertReportService } = alertReportServices()
const alertReport = ref(alertReportModel())
const hiddenLoader = ref(false)
const setMarkerCords = ref(null)

onBeforeMount(() => { getAlertReport() })

async function getAlertReport() {
    const response = await getAlertReportService(props.alertId)

    if (response.hasErrors) return console.error(`Error getting alert report: ${response.message}`)

    alertReport.value = alertReportModel(response.data)

    hiddenLoader.value = true
}
</script>