<template>
    <div class="alert-timeline__map" id="map"></div>
</template>

<script setup>
import { watch } from 'vue'

const props = defineProps(['location'])
let map = null
let marker = null
let cords = null

watch(() => props.location, (cordsObj) => {
    if (!cords) {
        cords = cordsObj
        return initMap(cords)
    }

    updateMarker(cordsObj)
})

// * Init Google Maps
function initMap(cords) {
    const element = document.getElementById("map")

    map = new window.google.maps.Map(element, {
        zoom: 16,
        center: cords,
        mapTypeId: "terrain",
        disableDefaultUI: false,
    })

    // * Se agrega el marker en el mapa
    // marker = new window.google.maps.marker.AdvancedMarkerElement({
    //     map,
    //     position: cords,
    //     title: 'Uluru',
    // })
    marker = new window.google.maps.Marker({
        position: cords,
        map,
        animation: window.google.maps.Animation.DROP,
    })
}

// * Update marker in Google Maps
function updateMarker(cords) {
    // * Center map on cords
    map.setCenter(cords)

    // * Update marker position
    marker.setPosition(cords)
}
</script>